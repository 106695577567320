import {createAction, props} from "@ngrx/store";
import {ChartsDto} from "@dto/charts.dto";
import {ChartsParamsDto} from "@dto/charts-params.dto";
import {NotificationDto} from "@dto/notification.dto";
import {NullableType} from "@type/nullable.type";
import {WebsocketUpdateDetailDto} from "@dto/websocket-update-detail.dto";

const state = "[Charts]";

export const initDashboard = createAction(
  `${state} Init charts`,
)

export const initDashboardSuccess = createAction(
  `${state} Init charts success`,
  props<{ charts: ChartsDto }>()
)

export const initDashboardFailure = createAction(
  `${state} Init charts failure`,
  props<{ error: string }>()
)

export const changeProductId = createAction(
  `${state} Change product id`,
  props<{ productId: string }>()
)

export const changeProductIdSuccess = createAction(
  `${state} Change product id success`,
  props<{ data: string[] }>()
)

export const changeProductIdFailure = createAction(
  `${state} Change product id failure`,
  props<{ error: string }>()
)

export const changeFilters = createAction(
  `${state} Change period`,
  props<{ filters: ChartsParamsDto }>()
)

export const changeFiltersSuccess = createAction(
  `${state} Change period success`,
  props<{ data: ChartsDto, filters: ChartsParamsDto }>()
)

export const changeFiltersFailure = createAction(
  `${state} Change period failure`,
  props<{ error: string }>()
)

export const updateNotificationRtList = createAction(
  `${state} Update notification list`,
  props<{ newNotification: NullableType<NotificationDto>, toUpdate: NullableType<WebsocketUpdateDetailDto> }>()
)
